import Api from 'api';
import { createSelector } from 'reselect';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { BILLING_FREQUENCIES_ID_MONTH, PRODUCT_IDS } from 'constants/packages';
import { QUOTE_VERSION_V3 } from 'constants/common';
import format from 'date-fns/format';
import { setGlobalLoading, unsetGlobalLoading } from './globalLoading';
import { getQuoteSelector } from './getQuote';
import { getEndorsement, addPackageDetails } from './quotePackages';

// action types
export const CLEAR_LOCATION = 'ri/quote/CLEAR_LOCATION';
export const CLEAR_QUOTE = 'ri/quote/CLEAR_QUOTE';
export const INIT_QUOTE = 'ri/quote/INIT_QUOTE';
export const UPDATE_PREMIUM = 'ri/quote/UPDATE_PREMIUM';
const REQUEST_QUOTE = 'ri/quote/REQUEST_QUOTE';
const RECEIVED_QUOTE = 'ri/quote/RECEIVED_QUOTE';
const UPDATE_QUOTE = 'ri/quote/UPDATE_QUOTE';
const REQUEST_PREMIUM_CALCULATION = 'ri/quote/REQUEST_PREMIUM_CALCULATION';
const RECEIVED_PREMIUM_CALCULATION = 'ri/quote/RECEIVED_PREMIUM_CALCULATION';

// reducer
const initialQuoteState = {
  billingFrequencies: {},
  location: {},
  products: {},
  isStatePolicyFeeApplicable: false,
  statePolicyFee: 0,
  statePolicyMessage: null,
  statePolicyFigaFee: 0,
  statePolicyFigaeFee: 0,
  availableProducts: {},
  liabilityLimits: [],
  personalContentLimits: [],
  deductibleLimits: [],
  hurricaneDeductibleLimits: [],
  ho4Endorsements: [],
  enhancementEndorsments: [],
  idTheftEndorsements: [],
  selectedProperty: null,
  liabilityLimitId: null,
  personalContentLimitId: null,
  deductibleLimitId: null,
  billingFrequency: BILLING_FREQUENCIES_ID_MONTH,
  hurricaneDeductibleLimitId: null,
  idTheftEndorsementId: null,
  ho4EndorsementIds: [],
  enhancementEndorsementId: null,
  ho4PolicyPremium: 0,
  idTheftPolicyPremium: 0,
  adminFee: 0,
  showSubmitLater: false,
  personalPremiumAmount: 0,
  liabilityPremium: 0,
  monthlyPremiumAmount: 0,
  premiumTaxAmount: 0,
  additionalTaxAmount: 0,
  earthQuakePremiumAmount: 0,
  nonHurricanePremium: 0,
  waterDemagePremium: 0,
  figaPremium: 0,
  figaePremium: 0,
  totalPremium: 0,
  totalQuarterlyPremium: 0,
  totalSemiannuallyPremium: 0,
  totalAnnualPremium: 0,
  nextCoveragePeriodStartDate: '',
  changesEffectiveOnDate: '',
  ho4EndorsmentsShowOnWebsite: [],
};

export const getPremiumAmountByFrequency = (billingFrequency, policyPremiumAmount) => {
  const premiumAmount = parseFloat(parseFloat(policyPremiumAmount).toFixed(2));
  switch (parseInt(billingFrequency, 10)) {
    case 4:
      return premiumAmount * 1;
    case 5:
      return premiumAmount * 3;
    case 6:
      return premiumAmount * 12;
    case 10:
      return premiumAmount * 6;
    default:
      return premiumAmount * 1;
  }
};

export const getRentersInsurancePremium = (billingFrequency, payload) => {
  const { totalPremium, totalQuarterlyPremium, totalSemiannuallyPremium, totalAnnualPremium } = payload;
  switch (parseInt(billingFrequency, 10)) {
    case 4:
      return totalPremium;
    case 5:
      return totalQuarterlyPremium;
    case 6:
      return totalAnnualPremium;
    case 10:
      return totalSemiannuallyPremium;
    default:
      return totalPremium;
  }
};

export default (state = initialQuoteState, action = {}) => {
  const { payload, type } = action;

  const addQuoteDetails = () => {
    const {
      selectedProperty,
      defaultBillingFrequency,
      showSubmitLater = false,
      availableProducts,
      deductibleLimits,
      liabilityLimits,
      personalContentLimits,
      hurricaneDeductibleLimits = [],
      ho4Endorsements,
      enhancementEndorsements,
      idTheftEndorsements = [],
      enhancementEndorsementId,
      defaultHo4Endorsements,
      defaultDeductibleLimit = null,
      defaultHurricaneDeductibleLimit = null,
      defaultLiabilityLimit = null,
      defaultPersonalContentLimit = null,
      defaultIdTheftEndorsement = null,
      premiumDetails,
      showIdTheftEndorsements = false,
      individualEndorsementTypeId = null,
      // isArrayEnrolmentEnabled = false,
      ho4EndorsmentsShowOnWebsite = [],
      // from change coverage area
      nextCoveragePeriodStartDate = '',
      changesEffectiveOnDate = '',
    } = payload;

    const {
      figaFee,
      figaeFee,
      isStatePolicyFeeApplicable,
      statePolicyFee,
      statePolicyMessage,
      adminFee,
      personalPremium,
      nonHurricanePremium,
      waterDamagePremium,
      figaPremium,
      figaePremium,
      earthquakePremium,
      liabilityPremium,
      taxPremium,
      premiumTaxAmount,
      premiumAmount,
      totalPremium,
      totalQuarterlyPremium,
      totalSemiannuallyPremium,
      totalAnnualPremium,
    } = premiumDetails;
    const billingFrequency = parseInt(defaultBillingFrequency, 10);

    const showIdTheft = showIdTheftEndorsements && Boolean(defaultIdTheftEndorsement) && idTheftEndorsements.length > 0;
    let idTheftPolicyPremium = 0;
    if (showIdTheft) {
      idTheftPolicyPremium = getPremiumAmountByFrequency(
        billingFrequency,
        parseFloat(defaultIdTheftEndorsement?.premiumAmount),
      );
    }

    const renterInsurencePremium = getRentersInsurancePremium(billingFrequency, premiumDetails);
    const ho4PolicyPremium = parseFloat(parseFloat(renterInsurencePremium - adminFee).toFixed(2));

    return {
      ...state,
      ho4EndorsmentsShowOnWebsite,
      nextCoveragePeriodStartDate,
      changesEffectiveOnDate,
      individualEndorsementTypeId,
      isArrayEnrolmentEnabled: false,
      showSubmitLater,
      liabilityLimits,
      personalContentLimits,
      deductibleLimits,
      hurricaneDeductibleLimits,
      ho4Endorsements,
      enhancementEndorsments: enhancementEndorsements,
      idTheftEndorsements: showIdTheft ? idTheftEndorsements : [],
      selectedProperty,
      availableProducts,
      liabilityLimitId: parseInt(defaultLiabilityLimit?.id, 10),
      personalContentLimitId: parseInt(defaultPersonalContentLimit?.id, 10),
      deductibleLimitId: parseInt(defaultDeductibleLimit?.id, 10),
      hurricaneDeductibleLimitId: parseInt(defaultHurricaneDeductibleLimit?.id, 10),
      idTheftEndorsementId: showIdTheft ? parseInt(defaultIdTheftEndorsement?.id, 10) : null,
      billingFrequency,
      ho4EndorsementIds: defaultHo4Endorsements,
      enhancementEndorsementId,
      ho4PolicyPremium,
      idTheftPolicyPremium,
      adminFee,
      statePolicyFigaFee: parseFloat(figaFee),
      statePolicyFigaeFee: parseFloat(figaeFee),
      statePolicyFee: parseFloat(statePolicyFee),
      isStatePolicyFeeApplicable,
      statePolicyMessage,
      personalPremiumAmount: personalPremium,
      liabilityPremium,
      monthlyPremiumAmount: premiumAmount,
      premiumTaxAmount,
      additionalTaxAmount: taxPremium,
      earthQuakePremiumAmount: earthquakePremium,
      nonHurricanePremium,
      waterDemagePremium: waterDamagePremium,
      figaPremium,
      figaePremium,
      totalPremium,
      totalQuarterlyPremium,
      totalSemiannuallyPremium,
      totalAnnualPremium,
    };
  };

  const updateQuote = () => {
    return {
      ...state,
      ...payload,
    };
  };

  switch (type) {
    case INIT_QUOTE:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PREMIUM:
      return {
        ...state,
        ...payload,
      };
    case RECEIVED_QUOTE:
      return addQuoteDetails();
    case UPDATE_QUOTE:
      return updateQuote();
    case CLEAR_QUOTE:
      return initialQuoteState;
    default:
      return state;
  }
};

export const requestQuote = (payload) => ({
  type: REQUEST_QUOTE,
  payload,
});

export const receivedQuote = (payload) => ({
  type: RECEIVED_QUOTE,
  payload,
});

export const updateQuoteDetails = (payload) => ({
  type: UPDATE_QUOTE,
  payload,
});

export const requestPremiumCalculation = (payload) => ({
  type: REQUEST_PREMIUM_CALCULATION,
  payload,
});

export const receivedPremiumCalculation = (payload) => ({
  type: RECEIVED_PREMIUM_CALCULATION,
  payload,
});

// selectors
const propertiesSelector = (state) => state.properties;
export const quoteDataSelector = (state) => state.quote;
const policiesSelector = (state) => state.policies;

export const propertySelector = createSelector(
  propertiesSelector,
  getQuoteSelector,
  quoteDataSelector,
  (properties, { property }, { location }) =>
    location.cid
      ? location
      : Object.values(properties).find((p) => parseInt(p.propertyId, 10) === parseInt(property, 10)),
);

export const getQuoteAndPremiumSelector = createSelector(
  quoteDataSelector,
  getQuoteSelector,
  propertiesSelector,
  policiesSelector,
  (
    {
      liabilityLimitId,
      personalContentLimitId,
      deductibleLimitId,
      billingFrequency,
      idTheftEndorsements,
      enhancementEndorsementId,
      ho4EndorsementIds,
      hurricaneDeductibleLimitId,
      idTheftEndorsementId,
      availableProducts = {},
    },
    { property, employee, leadSourceType, user, clientId, customerId, leaseId, applicantId, applicationId, startDate },
    properties,
    { showIdTheftEndorsements },
  ) => ({
    propertyId: property,
    employeeId: employee.id,
    leadSourceTypeId: leadSourceType,
    defaultBillingFrequency: properties[property].minimumBillingFrequency,
    userId: user,
    cid: clientId || properties[property].clientId,
    customerId,
    leaseId,
    applicantId,
    applicationId,
    selectedProperty: properties[property],
    startDate,
    liabilityLimitId,
    personalContentLimitId,
    deductibleLimitId,
    billingFrequency,
    enhancementEndorsementId,
    ho4EndorsementIds,
    hurricaneDeductibleLimitId,
    adminFee: availableProducts[PRODUCT_IDS.HO4]?.adminFee,
    idTheftEndorsements,
    idTheftEndorsementId,
    showIdTheftEndorsements,
  }),
);

function* getQuote({ payload }) {
  const {
    propertyId,
    employeeId,
    leadSourceTypeId,
    defaultBillingFrequency,
    userId,
    cid,
    customerId,
    leaseId,
    applicantId,
    applicationId,
    selectedProperty,
    startDate,
    showIdTheftEndorsements,
  } = yield select(getQuoteAndPremiumSelector);

  yield put(setGlobalLoading(`Almost done! We're preparing your quote now.`));

  const { quoteVersion = 'v2', entityId = null } = payload;

  try {
    const response = yield call(Api.getPricingFromAWS, {
      propertyId,
      employeeId,
      quoteVersion,
      leadSourceTypeId,
      userId,
      cid,
      customerId,
      leaseId,
      applicantId,
      applicationId,
      effectiveDate: format(startDate, 'MM-DD-YYYY'),
      entityId,
    });
    if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    const quoteData = { ...response.result, selectedProperty, defaultBillingFrequency, showIdTheftEndorsements };
    yield put(receivedQuote(quoteData));

    if (quoteVersion === QUOTE_VERSION_V3) {
      yield put(addPackageDetails(quoteData));
    }
    if (typeof payload.onComplete === 'function') {
      payload.onComplete();
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* premiumCalculation({ payload = {} }) {
  yield put(setGlobalLoading('Almost done! Calculating premium...'));
  try {
    const {
      propertyId,
      employeeId,
      liabilityLimitId,
      personalContentLimitId,
      deductibleLimitId,
      billingFrequency,
      enhancementEndorsementId,
      ho4EndorsementIds,
      hurricaneDeductibleLimitId,
      adminFee,
      startDate,
      idTheftEndorsements,
      idTheftEndorsementId,
    } = yield select(getQuoteAndPremiumSelector);

    const response = yield call(Api.calculatePremiumFromAWS, {
      employeeId,
      propertyId: parseInt(propertyId, 10),
      personalContentLimitId: parseInt(personalContentLimitId, 10),
      liabilityLimitId: parseInt(liabilityLimitId, 10),
      deductibleLimitId: parseInt(deductibleLimitId, 10),
      hurricaneDeductibleLimitId: parseInt(hurricaneDeductibleLimitId, 10),
      enhancementEndorsementId: parseInt(enhancementEndorsementId, 10),
      adminFee,
      billingFrequency: parseInt(billingFrequency, 10),
      effectiveDate: format(startDate, 'MM-DD-YYYY'),
      selectedEndorsements: ho4EndorsementIds,
      ...payload,
    });
    if (!(response.responseCode === 200)) {
      throw new Error(response.message);
    }

    const {
      adminFee: updatedAdminFee,
      premiumAmount,
      personalPremium,
      liabilityPremium,
      earthquakePremium,
      premiumTaxAmount,
      taxPremium,
      nonHurricanePremium,
      waterDamagePremium,
      figaPremium,
      figaePremium,
      totalPremium,
      totalQuarterlyPremium,
      totalSemiannuallyPremium,
      totalAnnualPremium,
    } = response.result;

    const renterInsurencePremium = getRentersInsurancePremium(billingFrequency, response.result);
    const ho4PolicyPremium = parseFloat(parseFloat(renterInsurencePremium - updatedAdminFee).toFixed(2));
    let idTheftPolicyPremium = 0;
    if (idTheftEndorsementId) {
      const selectedIdTheftEndorsement = getEndorsement(idTheftEndorsements, idTheftEndorsementId);
      idTheftPolicyPremium = getPremiumAmountByFrequency(
        billingFrequency,
        parseFloat(selectedIdTheftEndorsement?.premiumAmount),
      );
    }

    yield put(
      updateQuoteDetails({
        ho4PolicyPremium,
        liabilityPremium,
        nonHurricanePremium,
        figaPremium,
        figaePremium,
        totalPremium,
        totalQuarterlyPremium,
        totalSemiannuallyPremium,
        totalAnnualPremium,
        idTheftPolicyPremium,
        premiumTaxAmount,
        additionalTaxAmount: taxPremium,
        earthQuakePremiumAmount: earthquakePremium,
        waterDemagePremium: waterDamagePremium,
        monthlyPremiumAmount: premiumAmount,
        adminFee: updatedAdminFee,
        personalPremiumAmount: personalPremium,
      }),
    );

    if (typeof payload.onComplete === 'function') {
      payload.onComplete();
    }
  } catch (err) {
    if (window.NREUM?.noticeError) window.NREUM.noticeError(err);
    console.error(err);
  }

  yield put(unsetGlobalLoading());
}

function* watchRequestPremiumCalculation() {
  yield takeLatest(REQUEST_PREMIUM_CALCULATION, premiumCalculation);
}

function* watchGetQuote() {
  yield takeLatest(REQUEST_QUOTE, getQuote);
}

export const sagas = [watchGetQuote, watchRequestPremiumCalculation];
